<template>
  <div class="container">
    <van-tabs v-model="active" scrollspy sticky title-active-color="#0266E6">
      <van-tab v-for="(item,index) in list" :title="item.title" :key="index">
        <div class="content">
          <div class="name">{{item.title}}</div>
          <ul class="nav">
            <li class="nav-item" v-for="(items,indexs) in item.data" :key="indexs">
              <div v-if="items.label">{{items.label}}</div>
              <div v-if="!items.label" style="display:none"></div>
              <div v-if="items.value && (items.label != '注册日期' && items.label != '发证日期')">{{items.value}}</div>
              <div v-if="items.label == '注册日期' || items.label == '发证日期'">{{items.value | dateFormatMonthsText}}</div>
              <div v-if="!items.value">-</div>
            </li>
          </ul>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { reportInfos } from "@/utils/http.js";
export default {
  name: "",
  components: {},
  props: [],
  data() {
    return {
      active: 0,
      list: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getList(this.$route.query.id);
  },
  methods: {
    // reportInfos
    async getList(id) {
      const { data: res } = await reportInfos({ taskId: id });
      // console.log(res);
      res.content.forEach((item) => {
        item.data.forEach((ite) => {
          if (ite.value == "true") {
            ite.value = "有";
          } else if (ite.value == "false") {
            ite.value = "无";
          }
          // if ((ite.value = "false")) {
          //   ite.value = "无";
          // }
        });
      });
      this.list = res.content;
    },
  },
};
</script>
<style lang='less' scoped>
.container {
  background: #f8f8f8;
  font-family: PingFangSC-Medium, PingFang SC;
  padding-bottom: 20px;
}
.content {
  margin-top: 10px;
}
.nav {
  background: #ffffff;
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
}
.nav-item {
  // width: 92%;
  // margin: 0 auto;
  display: flex;
  height: 45px;
  align-items: center;
  border-top: 1px solid #e8e8e8;
  font-size: 16px;
  color: #333333;
}
.nav-item:nth-of-type(1) {
  border: none;
}
.nav-item > div:nth-of-type(1) {
  width: 110px;
  word-break: break-all;
  word-wrap: break-word;
  font-size: 15px;
  font-weight: 400;
}
.nav-item > div:nth-of-type(2) {
  flex: 1;
  word-break: break-all;
  word-wrap: break-word;
  margin-left: 15px;
  font-size: 16px;

  font-weight: 500;
}
.name {
  width: 100%;
  height: 46px;
  background: linear-gradient(180deg, #e7f0ff 0%, #ffffff 100%);
  border-radius: 10px 10px 0px 0px;
  line-height: 46px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  text-indent: 15px;
}
/deep/ .van-tabs__line {
  width: 20px !important;
  height: 10px !important;
  background-color: transparent !important;
  background: url("https://ftp.bmp.ovh/imgs/2021/05/503330710675b702.png")
    center center;
  background-size: 100% 100%;
}

/deep/ .van-tabs--line .van-tabs__wrap {
  height: 50px;
}
/deep/ .van-tab {
  font-size: 18px;
}
/deep/.van-tab--active > .van-tab__text {
  font-size: 20px;
}
</style>